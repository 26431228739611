import request, { ac_headers } from "@/utils/studio7ApiService";

export function renewEmailContentImageURL(content) {
  return request({
    url: "/node/send-email-nodes/renew-email-content",
    method: "post",
    data: { content },
    headers: ac_headers()
  });
}

import { presetDates } from "@/utils/time";

export const pickerOptions = (
  contentForm,
  timezone,
  showPreviewDialog = false,
  showRunNowDialog = false
) => {
  let shortCuts = [
    {
      text: __("Today"),
      onClick(picker) {
        contentForm.snapshot_time_frame = "today";
        picker.$emit("pick", [
          presetDates(timezone).today,
          presetDates(timezone).today
        ]);
      }
    },
    {
      text: __("Yesterday"),
      onClick(picker) {
        contentForm.snapshot_time_frame = "yesterday";
        picker.$emit("pick", [
          presetDates(timezone).yesterday,
          presetDates(timezone).yesterday
        ]);
      }
    },
    {
      text: __("Last 7 days"),
      onClick(picker) {
        contentForm.snapshot_time_frame = "last_7_days";
        picker.$emit("pick", [
          presetDates(timezone).sevenDaysAgo,
          presetDates(timezone).yesterday
        ]);
      }
    },
    {
      text: __("This Week"),
      onClick(picker) {
        contentForm.snapshot_time_frame = "this_week";
        picker.$emit("pick", [
          presetDates(timezone).startOfThisWeek,
          presetDates(timezone).endOfThisWeek
        ]);
      }
    },
    {
      text: __("Last Week"),
      onClick(picker) {
        contentForm.snapshot_time_frame = "last_week";
        picker.$emit("pick", [
          presetDates(timezone).startOfLastWeek,
          presetDates(timezone).endOfLastWeek
        ]);
      }
    },
    {
      text: __("This month"),
      onClick(picker) {
        contentForm.snapshot_time_frame = "this_month";
        picker.$emit("pick", [
          presetDates(timezone).startOfThisMonth,
          presetDates(timezone).endOfThisMonth
        ]);
      }
    },
    {
      text: __("Last month"),
      onClick(picker) {
        contentForm.snapshot_time_frame = "last_month";
        picker.$emit("pick", [
          presetDates(timezone).startOfLastMonth,
          presetDates(timezone).endOfLastMonth
        ]);
      }
    },
    {
      text: __("Last 3 months"),
      onClick(picker) {
        contentForm.snapshot_time_frame = "last_3_months";
        picker.$emit("pick", [
          presetDates(timezone).startOfLastThreeMonth,
          presetDates(timezone).endOfLastMonth
        ]);
      }
    }
  ];

  if (showPreviewDialog === true || showRunNowDialog === true) {
    shortCuts.push({
      text: __("Custom range"),
      onClick() {
        contentForm.snapshot_time_frame = "custom_range";
      }
    });
  }

  return {
    shortcuts: shortCuts,
    onPick: () => {
      // contentForm.snapshot_time_frame = "custom_range";
    }
  };
};
